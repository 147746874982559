import { PaletteColor, PaletteColorOptions, createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    aqua: PaletteColor;
    complianceBlue: PaletteColor;
    danger: PaletteColor;
    inactive: PaletteColor;
    invalid: PaletteColor;
    iris: PaletteColor;
    lightSuccess: PaletteColor;
    notice: PaletteColor;
    standardsGray: PaletteColor;
    color50555b: PaletteColor;
    colorfef6e4: PaletteColor;
    colorc2f3ce: PaletteColor;
    colordee5e7: PaletteColor;
    color0085b7: PaletteColor;
  }

  interface PaletteOptions {
    aqua: PaletteColorOptions;
    complianceBlue: PaletteColorOptions;
    danger: PaletteColorOptions;
    inactive: PaletteColorOptions;
    invalid: PaletteColorOptions;
    iris: PaletteColorOptions;
    lightSuccess: PaletteColorOptions;
    notice: PaletteColorOptions;
    standardsGray: PaletteColorOptions;
    color50555b: PaletteColorOptions;
    colorfef6e4: PaletteColorOptions;
    colorc2f3ce: PaletteColorOptions;
    colordee5e7: PaletteColorOptions;
    color0085b7: PaletteColorOptions;
  }
}

const colorTheme = createTheme({
  palette: {
    danger: {
      main: '#F05050'
    },
    invalid: {
      main: '#000000'
    },
    inactive: {
      main: '#BFC6C8'
    },
    lightSuccess: {
      main: '#a2cf6e'
    },
    notice: {
      main: '#ffcd38'
    },
    primary: {
      main: '#0086b7',
      dark: '#007ba9'
    },
    secondary: {
      main: '#48a9cc'
    },
    success: {
      main: '#27C24C'
    },
    warning: {
      main: '#F4B122'
    },
    standardsGray: {
      main: '#50545aff',
      light: '#50545a99'
    },
    complianceBlue: {
      main: '#0084b7'
    },
    aqua: {
      main: '#00a0aa'
    },
    iris: {
      main: '#0042b0'
    },
    color50555b: {
      main: '#50555b'
    },
    colorfef6e4: {
      main: '#fef6e4'
    },
    colorc2f3ce: {
      main: '#c2f3ce'
    },
    colordee5e7: {
      main: '#dee5e7'
    },
    color0085b7: {
        main: '#0085b7'
    }
  }
});

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    default: true;
    small: true;
    nav: true;
  }
}

declare module '@mui/material/LinearProgress' {
  interface LinearProgressPropsColorOverrides {
    danger: true;
    inactive: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    alert: true;
    nav: true;
    utility: true;
  }
}

const globalTheme = createTheme(
  {
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: 0
          }
        }
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:last-child td': {
              borderBottom: 0
            }
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontSize: '13px',
            color: '#50555b',
            height: '34px !important'
          },
          head: {
            fontWeight: 'bold',
            borderTop: '1px solid rgba(224, 224, 224, 1)'
          }
        }
      },
      MuiTypography: {
        defaultProps: {
          fontFamily: "'Open Sans', sans-serif"
        },
        variants: [
          {
            props: {
              variant: 'utility'
            },
            style: {
              color: colorTheme.palette.color50555b.main,
              fontSize: '0.75rem',
              textTransform: 'capitalize'
            }
          },
          {
            props: {
              variant: 'alert'
            },
            style: {
              fontSize: '23px',
              fontWeight: '300',
              textTransform: 'capitalize'
            }
          }
        ]
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            fontFamily: "'Open Sans',sans-serif",
            fontSize: '12px',
            color: '#bfc6c8',
            backgroundColor: '#edf1f2',
            height: '40px'
          }
        }
      },
      MuiButtonBase: {},
      MuiListItemButton: { },
      MuiButton: {
        variants: [
          {
            props: {
              variant: 'default'
            },
            style: {
              height: '46px',
              paddingLeft: '24px',
              paddingRight: '24px',
              textTransform: 'none',
              fontFamily: "'Open Sans',sans-serif",
              fontWeight: 'bold',
              fontSize: '12px',
              border: `2px solid ${colorTheme.palette.secondary.main}`,
              borderRadius: '0',
              color: '#50555B',
              backgroundColor: '#fff',
              '&:hover': {
                color: '#fff',
                backgroundColor: colorTheme.palette.secondary.main
              },
              '&:active': {
                color: '#fff',
                backgroundColor: colorTheme.palette.secondary.main
              }
            }
          },
          {
            props: {
              variant: 'small'
            },
            style: {
              textTransform: 'none',
              fontFamily: "'Open Sans',sans-serif",
              fontWeight: 'bold',
              fontSize: '12px',
              padding: '6px 12px',
              border: `2px solid ${colorTheme.palette.secondary.main}`,
              borderRadius: '0',
              color: '#50555B',
              backgroundColor: '#fff',
              '&:hover': {
                color: '#fff',
                backgroundColor: colorTheme.palette.secondary.main
              },
              '&:active': {
                color: '#fff',
                backgroundColor: colorTheme.palette.secondary.main
              }
            }
          },
          {
            props: {
              variant: 'nav'
            },
            style: {
              height: '50px',
              paddingLeft: '16px',
              paddingRight: '16px',
              fontFamily: "'Open Sans',sans-serif",
              fontSize: '13px',
              borderRadius: '0',
              color: '#85deff',
              backgroundColor: colorTheme.palette.primary.main,
              '&:hover': {
                backgroundColor: colorTheme.palette.primary.dark
              },
              '&:active': {
                backgroundColor: colorTheme.palette.primary.dark
              }
            }
          }
        ]
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            fontFamily: "'Open Sans',sans-serif",
            fontSize: '13px'
          }
        },
        variants: [
          {
            props: {
              variant: 'permanent'
            },
            style: {
              '&:hover': {
                backgroundColor: '#f6f8f8'
              },
              '&:active': {
                backgroundColor: '#f6f8f8'
              },
              '& .MuiDrawer-paper': {
                border: 'none',
                height: 'calc(100vh - 50px)',
                backgroundColor: '#edf1f2',
                boxSizing: 'border-box',
                marginTop: '50px',
                width: 220,
                fontFamily: "'Open Sans', sans-serif",
                fontSize: '13px'
              }
            }
          }
        ]
      },
      MuiLink: {
        variants: [
          {
            props: {
              variant: 'nav'
            },
            style: {
              fontSize: '13px',
              color: '#333333',
              textDecoration: 'none',
              minWidth: '150px'
            }
          },
          {
            props: {
              variant: 'utility'
            },
            style: {
              color: colorTheme.palette.standardsGray.main,
              textDecorationThickness: '2px',
              textDecorationColor: colorTheme.palette.standardsGray.main,
              '&:hover': {
                cursor: 'pointer',
                opacity: 0.75
              }
            }
          }
        ]
      },
      MuiLinearProgress: { }
    }
  },
  colorTheme
);

export default globalTheme;
