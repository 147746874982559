import axios from 'axios';

import constants from '../../../constants';
import { ChecklistCertification, CustomerTraining, Training, UserTraining } from './types';
import { TeamMember } from '../../../types';

const ontraqBaseAPIURL = `${constants.ONTRAQ_API_URL}/api`;

export const getDELink = (customerNumber: string) =>
  axios.post(`${ontraqBaseAPIURL}/Token/getVendorToken`, { tokenDetails: { customerNumber, partnerName: 'DE' } }).then((res) => {
    const token = res.data.data.jwtToken;

    if (token === undefined) {
      throw new Error('there was an issue getting the token');
    }

    return `${constants.DE_WEB_URL}/login/?jwtToken=${token}`;
  });

export const getUpcomingAppointments = (customerNumber: string) => {
  return axios.get<{ started_at: string }>(`${ontraqBaseAPIURL}/Customers/${customerNumber}/nextDEAppointment`).then((res) => res.data);
};

export const certifyChecklistComplete = (customerNumber: string, checklistId: number) =>
  axios
    .post<ChecklistCertification>(`${ontraqBaseAPIURL}/Customers/${customerNumber}/trainingCompliance/checklistCertifications/${checklistId}`)
    .then((res) => res.data);

export const decertifyChecklist = (customerNumber: string, checklistId: number) =>
  axios.delete(`${ontraqBaseAPIURL}/Customers/${customerNumber}/trainingCompliance/checklistCertifications/${checklistId}`);

export const getCompletedChecklists = (customerNumber: string) =>
  axios
    .get<ChecklistCertification[]>(`${ontraqBaseAPIURL}/Customers/${customerNumber}/trainingCompliance/checklistCertifications`)
    .then((res) => res.data);

// TODO: it might be possible to filter by type as part of the request query, but I couldn't get it working.
// Regex was used here because currently "OSHA-HIPAA " is the type :weary:
const filterByOshaHippaType = <T extends { training: Training }>(data: T[]): T[] =>
  data.filter((d) => /OSHA-HIPAA/i.test(d.training.ref_item?.type ?? ''));

export const getTeamMembers = async (customerNumber: string) => {
  const query = {
    include: [
      {
        relation: 'groups',
        scope: {
          include: 'name'
        }
      }
    ],
    where: {
      customer_number: customerNumber
    }
  };
  try {
    return await axios.get<TeamMember[]>(`${constants.ONTRAQ_API_URL}/api/UsersPractices?filter=${JSON.stringify(query)}`)
    .then(res => res.data);
  } catch (error) {
    console.error(error);
    return [];
  }
}

export const getUserTraining = (customerNumber: string) =>
  axios
    .get<UserTraining[]>(
      `${ontraqBaseAPIURL}/UsersTrainings?filter=${JSON.stringify({
        where: {
          customer_number: customerNumber,
          is_active: true
        },
        include: [
          {
            relation: 'training',
            scope: {
              include: ['ref_item']
            }
          }
        ]
      })}`
    )
    .then((res) => res.data)
    .then(filterByOshaHippaType);

export const getCustomerTraining = (customerNumber: string) =>
  axios
    .get<CustomerTraining[]>(
      `${ontraqBaseAPIURL}/CustomerTrainings?filter=${JSON.stringify({
        where: {
          customer_number: customerNumber,
          is_active: true
        },
        include: [
          {
            relation: 'training',
            scope: {
              include: ['ref_item']
            }
          }
        ]
      })}`
    )
    .then((res) => res.data)
    .then(filterByOshaHippaType);
