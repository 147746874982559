import axios from 'axios';
import constants from '../constants';
import { PracticeInfo, Program, TrainingChecklist } from '../views/practice/dentalenhancements/types';
import { CustomerOshaHipaaMetadata } from '../types';

const getProgramsAndChecklists = async (
  customerNumber: string
): Promise<{
  training_checklists: TrainingChecklist[];
  training_programs: Program[];
  customer_osha_hipaa_metadata: Partial<CustomerOshaHipaaMetadata>;
  practice_info?: PracticeInfo;
}> => {
  return await axios
    .get(`${constants.ONTRAQ_API_URL}/api/Customers/${customerNumber}?filter={"meta":["training_programs","training_checklists"], "include": {"relation": "customer_osha_hipaa_metadata"}}`)
    .then((res) => {
      const {
        training_checklists,
        training_programs,
        customer_osha_hipaa_metadata,
        name,
        doctor_name,
        street,
        street2,
        city,
        state,
        zip,
        phone
      } = res.data;

      return {
        training_checklists,
        training_programs,
        customer_osha_hipaa_metadata,
        practice_info: { name, doctor_name, street, street2, city, state, zip, phone }
      };
    })
    .catch((error) => {
      console.error(error);
      return { training_checklists: [], training_programs: [], customer_osha_hipaa_metadata: {} };
    });
};

const getTaskCompliances = async (customerNumber: string) => {
  return await axios
    .get(`${constants.ONTRAQ_API_URL}/api/Customers/${customerNumber}/trainingCompliance/checklistTasks`)
    .then((res) => {
      const training_checklist_task_compliance = res.data;

      return training_checklist_task_compliance;
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
};

const setTaskMetadata = async (customerNumber: string, task_id: number, status: number) => {
  const payload = {
    checklistTaskId: task_id,
    status
  };
  return await axios
    .post(`${constants.ONTRAQ_API_URL}/api/Customers/${customerNumber}/trainingCompliance/checklistTasks/${task_id}`, { ...payload })
    .then((res) => {
      const data = res.data;

      return data;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

const setCustomerNotesForTask = async (customerNumber: string, taskId: number, notes: string) => {
  const url = `${constants.ONTRAQ_API_URL}/api/Customers/${customerNumber}/checklistTaskNotes/${taskId}`;
  const payload = { notes };

  try {
    const { data } = await axios.post(url, payload);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export { getProgramsAndChecklists, getTaskCompliances, setTaskMetadata, setCustomerNotesForTask };
