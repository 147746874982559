import { DialogContent, Dialog, DialogTitle, DialogActions, Button, DialogContentText } from '@mui/material';

const GenericDeleteModal = ({ open, onClose, onDelete }: { open: boolean; onClose: () => void; onDelete: () => void }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Are you sure you want to delete this entry?</DialogTitle>
      <DialogContent>
        <DialogContentText>This action cannot be undone.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button onClick={() => onDelete()}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenericDeleteModal;
