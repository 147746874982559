import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import * as R from 'remeda';

import { RootState } from './store';
import { ChildCustomerDetails } from '../types';

const initialState: Pick<ChildCustomerDetails, 'customer_number' | 'customerService' | 'doctor_name' | 'email' | 'name' | 'phone' | 'city' | 'state' | 'street' | 'zip'> = {
  customer_number: '',
  customerService: [],
  doctor_name: '',
  email: '',
  name: '',
  phone: '',
  city: '',
  state: '',
  street: '',
  zip: ''
};

export const childCustomerSlice = createSlice({
  name: 'childCustomer',
  initialState,
  reducers: {
    setChildCustomer: (_, action: PayloadAction<ChildCustomerDetails>) => {
      return R.pick(action.payload, ['customer_number', 'customerService', 'doctor_name', 'email', 'name', 'phone', 'city', 'state', 'street', 'zip']);
    },
    clearChildCustomer: () => {
      return initialState;
    }
  }
});

export const { setChildCustomer, clearChildCustomer } = childCustomerSlice.actions;

export const selectChildCustomerNumber = (state: RootState) => state.childCustomer.customer_number;

export const selectChildHasDE = (state: RootState) => state.childCustomer.customerService?.some((service) => service.serviceType === 'DE') ?? false;

export default childCustomerSlice.reducer;
