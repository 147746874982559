import React from 'react';
import PracticeOshaHipaa from '../../../views/practice/dentalenhancements/OshaHipaa';
import PracticeDashboard from '../../../views/corp/practice/PracticeDashboard';
import { childrenDERoutes } from '../../practice/dentalenhancements/DERoutes';

const PracticeRoutes = [
  {
    path: 'corp/practice/:customerNumber', // might rename to childCustomerNumber
    element: <PracticeDashboard />,
    children: [
      {
        path: 'osha-hipaa/:year?',
        element: <PracticeOshaHipaa />,
        children: childrenDERoutes
      }
    ]
  }
];

export default PracticeRoutes;
