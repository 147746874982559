import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import * as R from 'remeda';

import { RootState } from './store';
import { CustomerDetails } from '../types';

export interface CustomerState extends Pick<CustomerDetails, 'corporate_parent' | 'customer_number' | 'customerService' | 'email' | 'groups' | 'name'> {
  currentCustomerNumber: string;
  corporateView: boolean;
}

const initialState: CustomerState = {
  corporate_parent: false,
  customer_number: '',
  customerService: [],
  email: '',
  groups: [],
  name: '',
  currentCustomerNumber: '',
  corporateView: false
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomer: (state, action: PayloadAction<CustomerState>) => {
      return {
        ...state,
        ...R.pick(action.payload, ['corporate_parent', 'customer_number', 'customerService', 'email', 'groups', 'name'])
      };
    },
    setCurrentCustomerNumber: (state, action: PayloadAction<string>) => {
      state.currentCustomerNumber = action.payload;
    },
    setCorporateView: (state, action: PayloadAction<boolean>) => {
      state.corporateView = action.payload;
    },
    clearCustomer: () => {
      return initialState;
    }
  }
});

export const { setCustomer, setCurrentCustomerNumber, setCorporateView, clearCustomer } = customerSlice.actions;

export const selectGroups = (state: RootState) => state.customer.groups;

export const selectCustomerNumber = (state: RootState) => state.customer.customer_number;

export const selectCurrentCustomerNumber = (state: RootState) => state.customer.currentCustomerNumber;

export const selectCorporateView = (state: RootState) => state.customer.corporateView;

export const selectHasDE = (state: RootState) =>
  state.customer.customerService?.some((service) => service.serviceType === 'DE' && service.isActive) ?? false;

export default customerSlice.reducer;
