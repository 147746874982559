import { Program } from './views/practice/dentalenhancements/types';

export interface CustomerService {
  id: string;
  isActive: boolean;
  serviceType: string;
  customerNumber: string;
}

export interface TrainingInvoiceItem {
  customer_number: string;
  invoice_item_id: number;
  sku: string;
  year: number;
}

export interface CustomerDetails {
  customer_number: string;
  email: string;
  name: string;
  corporate_parent: boolean;
  groups: Groups[];
  customerService: CustomerService[];
}

export interface Compliance {
  category: string;
  compliance_code: string;
  compliance_status: ComplianceStatus;
  message_long: string;
  message_short: string;
  priority: number;
}

export interface CustomerOshaHipaaMetadata {
  id: number;
  compliance_code: string;
  customer_number: string;
  hipaa_compliance_status: ComplianceStatus;
  osha_compliance_status: ComplianceStatus;
  training_compliance_status: ComplianceStatus;
  percentage_complete: number;
  updated_date: string;
  compliance: Compliance;
}

export interface ChildCustomerDetails {
  customer_number: string;
  customerService: CustomerService[];
  doctor_name: string;
  email: string;
  name: string;
  phone: string;
  city: string;
  state: string;
  street: string;
  zip: string;
  customer_osha_hipaa_metadata?: CustomerOshaHipaaMetadata;
}

export interface Group {
  user_id: string;
  group_id: number;
  name: GroupName;
}

export interface Groups {
  group_id: number;
  name: string;
  customer_number: string;
  members: Members[];
}

export interface GroupName {
  group_id: number;
  name: string;
  customer_number: string;
}

export interface Members {
  group_id: number;
  customer_number: string;
}

export interface TeamMember {
  key_: number;
  user_id: string;
  customer_number: string;
  loc_id: number;
  firstname?: string;
  middlename?: string;
  lastname?: string;
  email: string;
  access_level: string;
  account_active: boolean;
  cellphone?: string;
  position?: string;
  login_user_id: string;
  last_connection_date?: string;
  verified: boolean;
  email_verified: boolean;
  groups?: Group[];
}

export enum ComplianceStatus {
  Complete = 1,
  InProgress = 2,
  NotStarted = 3,
  NotApplicable = 4
}
