import React, { ReactNode, useMemo } from 'react';
import { Chart, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import './ComplianceDonut.css';

Chart.register(ArcElement, Tooltip);

type status = {
  status: number;
};

type compliance = {
  compliance: status;
};

type Props = {
  values: compliance[] | undefined;
  label: string;
  image: string;
  children?: ReactNode[] | ReactNode;
};

function ComplianceDonut({ values, label, image, children }: Props) {
  const complianceColors = ['#F7464A', '#F4B122', '#27C24C', '#BFC6C8'];
  const labels = ['Action Required', 'Attention Needed', 'Up to Date', 'Inactive'];
  enum ComplianceStatus {
    UpToDate = 1,
    AttentionNeeded = 2,
    ActionRequired = 3,
    Inactive = 4
  }

  const complianceCounts = useMemo(() => {
    let counts = [0, 0, 0, 0];

    if (values && values.length > 0) {
      values.forEach((value) => {
        const compliance = value?.compliance?.status;
        if (compliance) {
          switch (compliance) {
            case ComplianceStatus.UpToDate:
              counts[2]++;
              break;
            case ComplianceStatus.AttentionNeeded:
              counts[1]++;
              break;
            case ComplianceStatus.ActionRequired:
              counts[0]++;
              break;
            case ComplianceStatus.Inactive:
              counts[3]++;
              break;
          }
        }
      });
    }

    return counts;
  }, [values]);

  // if all values are 0, default to all gray instead of not displaying
  const chartData = complianceCounts.some((value) => !!value) ? complianceCounts : [0, 0, 0, 1];

  const data = {
    labels,
    datasets: [
      {
        data: chartData,
        backgroundColor: complianceColors,
      },
    ],
  };

  return (
    <div className="chart-container" data-testid="compliance-donut">
      <div className="chart-donut">
        <Doughnut
          className={image}
          data={data}
          options={{
            cutout: '70%',
            responsive: true,
            maintainAspectRatio: false
          }}
        />
      </div>
      <div className="chart-text">{label}</div>
      <div className="compliance-count">
        {complianceCounts.map((value, key) => {
          if (value > 0) {
            return (
              <div className="compliance-dot-container" key={key}>
                <div className="compliance-dot" style={{ background: complianceColors[key] }}></div>
                <span className="compliance-dot-count">{value}</span>
              </div>
            );
          }
        })}
      </div>
      { children ?? null }
    </div>
  );
}

export default ComplianceDonut;
