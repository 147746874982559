import format from 'date-fns/format';
import { P, match } from 'ts-pattern';

import { ComplianceStatus } from '../../../types';
import { User } from '../../../redux/user';

import { ChecklistCertification, ProgramComponentTypeName, TrainingChecklistTasksMetadata, TrainingChecklistType } from './types';

export const getFullNameOrEmailOrDefault = (user?: User, defaultName = 'NA') => {
  if (!user || !user?.email) {
    return defaultName;
  }

  const bothNames = user.firstname && user.lastname;

  if (user.firstname || user.lastname) {
    return `${user.firstname ?? ''}${bothNames && ' '}${user.lastname ?? ''}`;
  }

  return user.email;
};

const checklistTypeToCapitalizedString = (checklistType: TrainingChecklistType | undefined): string =>
  match(checklistType)
    .with('checklist', () => 'Checklist')
    .with('facility report', () => 'Facility Report')
    .with('security risk assessment', () => 'Security Risk Assessment')
    .with(P.nullish, () => 'Checklist')
    .exhaustive();

export const getCertificationMessage = (certification: ChecklistCertification, includeUser = true) =>
  `${checklistTypeToCapitalizedString(certification.checklistType)} certified complete on ${format(
    new Date(certification.certified_at),
    'MM/dd/yy'
  )}${includeUser ? ` by ${getFullNameOrEmailOrDefault(certification.user, `User ID ${certification.user_id}`)}` : ''}`;

/**
 * This function exists because even though these are db tables, we expect these component types
 * to be mostly static as they were seeded with the creation of said tables.
 */
export const componentTypeNameToId = (componentTypeName?: string): number => {
  switch (componentTypeName?.toUpperCase().trim()) {
    case 'OSHA':
      return 1;
    case 'HIPAA':
      return 2;
    case 'TRAINING':
      return 3;
    case 'WELCOME':
      return 4;
    default:
      return 0;
  }
};

export const componentTypeIdToName = (id: number): ProgramComponentTypeName => {
  switch (id) {
    case 1:
      return 'OSHA';
    case 2:
      return 'HIPAA';
    case 3:
      return 'TRAINING';
    case 4:
      return 'WELCOME';
    default:
      throw new Error(`unknown component type id ${id}`);
  }
};

export const taskIsCompliant = (task?: TrainingChecklistTasksMetadata): boolean => {
  if (!task) {
    return false;
  }

  return task.compliance_status === ComplianceStatus.NotApplicable || task.compliance_status === ComplianceStatus.Complete;
};

export const compositeComplianceStatus = (statuses: ComplianceStatus[]): ComplianceStatus => {
  const uniqueStatuses = new Set(statuses);

  if (uniqueStatuses.has(ComplianceStatus.NotStarted)) {
    return ComplianceStatus.NotStarted;
  }

  if (uniqueStatuses.has(ComplianceStatus.InProgress)) {
    return ComplianceStatus.InProgress;
  }

  if (uniqueStatuses.has(ComplianceStatus.Complete)) {
    return ComplianceStatus.Complete;
  }

  return ComplianceStatus.NotApplicable;
};

export const getChecklistTypeDisplayName = (type: TrainingChecklistType) =>
  match(type)
    .with('checklist', () => 'Checklist')
    .with('facility report', () => 'Facility Report')
    .with('security risk assessment', () => 'Security Risk Assessment')
    .exhaustive();

export enum TrainingChecklistTypeSlug {
  FacilityReport = 'facility-report',
  Checklist = 'checklist',
  SecurityRiskAssessment = 'sra'
}

export const checklistTypeToSlug = (type: TrainingChecklistType): TrainingChecklistTypeSlug =>
  match(type)
    .with('checklist', () => TrainingChecklistTypeSlug.Checklist)
    .with('facility report', () => TrainingChecklistTypeSlug.FacilityReport)
    .with('security risk assessment', () => TrainingChecklistTypeSlug.SecurityRiskAssessment)
    .exhaustive();

export const checklistTypeFromSlug = (type?: string): TrainingChecklistType =>
  match(type)
    .with(TrainingChecklistTypeSlug.Checklist, (): TrainingChecklistType => 'checklist')
    .with(TrainingChecklistTypeSlug.FacilityReport, (): TrainingChecklistType => 'facility report')
    .otherwise((): TrainingChecklistType => 'checklist');

// these need to be cast because of a TS quirk with numeric enums
// https://github.com/gvergnaud/ts-pattern/issues/183
export const getComplianceStatusText = (status: ComplianceStatus) =>
  match(status)
    .with(ComplianceStatus.Complete as 1, () => 'Complete')
    .with(ComplianceStatus.InProgress as 2, () => 'In Progress')
    .with(ComplianceStatus.NotStarted as 3, () => 'Not Started')
    .with(ComplianceStatus.NotApplicable as 4, () => 'Not Applicable')
    .exhaustive();
