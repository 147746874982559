import axios from 'axios';
import Cookies from 'universal-cookie';
import decode from 'jwt-decode';
import constants from '../constants';

export default class AuthService {
  constructor() {
    this.cookies = new Cookies();
  }

  async createSession() {
    const currentCustomerNumber = this.getCurrentCustomerNumber() ? this.getCurrentCustomerNumber() : '';

    return await axios
      .post(`${constants.ONTRAQ_API_URL}/api/Users/createSession`, { currentCustomerNumber })
      .then((res) => {
        const userData = res.data.data;
        this.setCurrentUser(userData);

        if (!currentCustomerNumber) {
          this.setCurrentCustomerNumber(userData.customer_number);
        }

        return userData;
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  }

  getCurrentCustomerNumber() {
    return this.cookies.get('currentCustomerNumber');
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  getDEToken() {
    return localStorage.getItem('deToken');
  }

  getLoginUrl(uri) {
    return constants.LOGIN_URL + uri;
  }

  getToken() {
    return this.cookies.get('jwtToken');
  }

  isTokenExpired(token) {
    const decoded = decode(token);
    if (!decoded?.exp && decoded.exp < Date.now() / 1000) {
      this.logout();
      return true;
    }
    return false;
  }

  async loggedIn() {
    const token = this.getToken();
    const loggedIn = !!token && !this.isTokenExpired(token);

    if (!loggedIn) {
      return this.redirectToLogin();
    }
    return true;
  }

  logout() {
    this.cookies.remove('currentCustomerNumber', { path: '/' });
    this.cookies.remove('jwtToken', { path: '/' });
    localStorage.clear();
    this.redirectToLogin();
  }

  redirectToLogin() {
    const redirectUrl = encodeURIComponent(window.location.href);
    const loginUrl = encodeURIComponent(this.getLoginUrl(`/?service=ontraq&redirectUrl=${redirectUrl}`));
    window.location.href = this.getLoginUrl(`/logout?service=ontraq&redirectUrl=${loginUrl}`);
  }

  setCurrentCustomerNumber(customerNumber) {
    this.cookies.set('currentCustomerNumber', customerNumber, { path: '/' });
  }

  setCurrentUser(user) {
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  setDEToken(token) {
    localStorage.setItem('deToken', token);
  }
}
