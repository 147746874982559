import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { usePromiseTracker } from 'react-promise-tracker';
import './TrainingComplianceReport.css';
import constants from '../../../constants';
import { selectGroups } from '../../../redux/customer';
import { ComplianceStatus } from '../../../types';

const initComplianceCounts = {
  completed: 0,
  due: 0,
  pastDue: 0,
  inactive: 0,
  completedPercentage: 0,
  duePercentage: 0,
  pastDuePercentage: 0,
  inactivePercentage: 0
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function TrainingComplianceReport({ courses, customerTrainings, userTrainingStatus }) {
  const { promiseInProgress } = usePromiseTracker();
  const groups = useSelector(selectGroups);
  const [customers, setCustomers] = useState([]);
  const [complianceCounts, setComplianceCounts] = useState({ ...initComplianceCounts });
  const [complianceFilter, setComplianceFilter] = useState('');
  const [courseFilter, setCourseFilter] = useState('');
  const [displayedCustomers, setDisplayedCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [groupFilter, setGroupFilter] = useState([]);
  const [groupMembers, setGroupMembers] = useState([]);
  const [pageLimit, setPageLimit] = useState(25);
  const [reverseSort, setReverseSort] = useState(false);
  const [sortType, setSortType] = useState('training_compliance_status');
  const [search, setSearch] = useState('');
  const [tablePage, setTablePage] = useState(0);

  const calculatePercentage = (count, total) => {
    const percentage = (count / total) * 100;

    if (isNaN(percentage)) {
      return 0;
    }
    return Math.round(percentage);
  };

  const showSortIcon = (sortField) => {
    if (sortField !== sortType) {
      return;
    }

    if (reverseSort) {
      return <FontAwesomeIcon icon="caret-up" size="sm" className="m-l-8" />;
    }

    return <FontAwesomeIcon icon="caret-down" size="sm" className="m-l-8" />;
  };

  const displayCustomers = () => {
    const sliceStart = tablePage * pageLimit;
    const sliceEnd = (tablePage + 1) * pageLimit;
    const displayCustomers = filteredCustomers.slice(sliceStart, sliceEnd);
    setDisplayedCustomers(displayCustomers);
  };

  const filterCustomers = () => {
    setTablePage(0);
    let filteredCustomers = JSON.parse(JSON.stringify(customers)).sort((a, b) => {
      if (sortType === 'training_compliance_status') {
        if (a[sortType] === ComplianceStatus.NotApplicable) return 1;
        if (b[sortType] === ComplianceStatus.NotApplicable) return -1;
      }

      if (reverseSort) {
        return a[sortType] < b[sortType] ? -1 : a[sortType] > b[sortType] ? 1 : 0;
      }

      return b[sortType] < a[sortType] ? -1 : b[sortType] > a[sortType] ? 1 : 0;
    });

    if (search !== '') {
      const cleanSearch = search.toLowerCase().trim();

      filteredCustomers = filteredCustomers.filter((customer) => {
        return customer.name.toLowerCase().includes(cleanSearch) || customer.city.toLowerCase().includes(cleanSearch);
      });
    }

    if (complianceFilter) {
      filteredCustomers = filteredCustomers.filter((customer) => {
        return customer.training_compliance_status === complianceFilter;
      });
    }

    if (courseFilter) {
      filteredCustomers = filteredCustomers.filter((customer) => {
        let matched = false;
        let completed = 0;
        let dueSoon = 0;
        let pastDue = 0;

        if (customer.users_training.length > 0) {
          for (let training of customer.users_training) {
            if (Number(training.course_id) === Number(courseFilter)) {
              matched = true;
              if (training.compliance_status === 1) {
                completed++;
              } else if (training.compliance_status === 2) {
                dueSoon++;
              } else if (training.compliance_status === 3) {
                pastDue++;
              }
            }
          }
        }

        if (matched) {
          customer.training_completed_count = completed;
          customer.training_due_soon_count = dueSoon;
          customer.training_past_due_count = pastDue;
          return customer;
        }
      });
    }

    if (groupFilter.length) {
      const members = groupFilter.flatMap((group) => group?.members.map((member) => member.customer_number));
      if (members) {
        filteredCustomers = filteredCustomers.filter((customer) => members.includes(customer.customer_number));
      }
    }

    setFilteredCustomers([...filteredCustomers]);
  };

  const processCustomerData = () => {
    const counts = { ...initComplianceCounts };
    setComplianceFilter('');
    setCourseFilter('');
    setGroupFilter([]);
    const customerCount = customers.length;

    if (customerCount) {
      customers.map((customer) => {
        if (customer.training_compliance_status === 1) {
          counts.completed++;
        } else if (customer.training_compliance_status === 2) {
          counts.due++;
        } else if (customer.training_compliance_status === 3) {
          counts.pastDue++;
        } else if (customer.training_compliance_status === 4) {
          counts.inactive++;
        }
      });

      counts.completedPercentage = calculatePercentage(counts.completed, customerCount);
      counts.duePercentage = calculatePercentage(counts.due, customerCount);
      counts.pastDuePercentage = calculatePercentage(counts.pastDue, customerCount);
      counts.inactivePercentage = calculatePercentage(counts.inactive, customerCount);
    }

    setComplianceCounts(counts);
  };

  const handleComplianceFilter = (event) => {
    let { value } = event.target;
    value = isNaN(value) ? '' : Number(value);

    if (value === complianceFilter) {
      setComplianceFilter('');
    } else {
      setComplianceFilter(value);
    }
  };

  const handleCourse = (event) => {
    const { value } = event.target;
    setCourseFilter(value);
  };

  const handleGroups = (groups) => {
    setGroupFilter(groups);
  };

  const handlePageLimit = (event) => {
    const { value } = event.target;
    setPageLimit(value);
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  const handleSort = (sortField) => {
    if (sortField === sortType) {
      setReverseSort(!reverseSort);
    } else {
      setSortType(sortField);
    }
  };

  const handleTablePage = (event, newPage) => {
    setTablePage(newPage);
  };

  useEffect(() => {
    setGroupMembers([...groups]);
  }, [groups]);

  useEffect(() => {
    setCustomers(customerTrainings);
  }, [customerTrainings]);

  useEffect(() => {
    processCustomerData();
  }, [customers]);

  useEffect(() => {
    filterCustomers();
  }, [complianceFilter, courseFilter, customers, groupFilter, reverseSort, sortType, search]);

  useEffect(() => {
    displayCustomers();
  }, [filteredCustomers, pageLimit, tablePage]);

  const complianceColors = ['background-success', 'background-warning', 'background-danger', 'background-inactive'];

  return (
    <div>
      <div className="flex flex-space-between flex-align-start m-b-16">
        <Card variant="outlined" className="m-r-25 flex-1">
          <div className="box-header">Compliance Status</div>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '10%' }}>Practices</TableCell>
                <TableCell sx={{ width: '30%' }}></TableCell>
                <TableCell sx={{ width: '40%' }}>Status</TableCell>
                <TableCell sx={{ width: '20%' }}>Filter by</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {!promiseInProgress && (
                  <>
                    <TableCell>
                      <span className="compliance-box background-danger">{complianceCounts.pastDue}</span>
                    </TableCell>
                    <TableCell>
                      <LinearProgress sx={{ height: '8px' }} variant="determinate" color="danger" value={complianceCounts.pastDuePercentage} />
                    </TableCell>
                  </>
                )}
                {promiseInProgress && (
                  <>
                    <TableCell>
                      <Skeleton variant="rounded" width={24} height={23} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rectangular" height={8}></Skeleton>
                    </TableCell>
                  </>
                )}
                <TableCell>Past Due</TableCell>
                <TableCell>
                  <Checkbox value={3} checked={complianceFilter === 3 ? true : false} onChange={handleComplianceFilter} />
                </TableCell>
              </TableRow>
              <TableRow>
                {!promiseInProgress && (
                  <>
                    <TableCell>
                      <span className="compliance-box background-warning">{complianceCounts.due}</span>
                    </TableCell>
                    <TableCell>
                      <LinearProgress sx={{ height: '8px' }} variant="determinate" color="warning" value={complianceCounts.duePercentage} />
                    </TableCell>
                  </>
                )}
                {promiseInProgress && (
                  <>
                    <TableCell>
                      <Skeleton variant="rounded" width={24} height={23} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rectangular" height={8}></Skeleton>
                    </TableCell>
                  </>
                )}
                <TableCell>Training Due</TableCell>
                <TableCell>
                  <Checkbox value={2} checked={complianceFilter === 2 ? true : false} onChange={handleComplianceFilter} />
                </TableCell>
              </TableRow>
              <TableRow>
                {!promiseInProgress && (
                  <>
                    <TableCell>
                      <span className="compliance-box background-success">{complianceCounts.completed}</span>
                    </TableCell>
                    <TableCell>
                      <LinearProgress sx={{ height: '8px' }} variant="determinate" color="success" value={complianceCounts.completedPercentage} />
                    </TableCell>
                  </>
                )}
                {promiseInProgress && (
                  <>
                    <TableCell>
                      <Skeleton variant="rounded" width={24} height={23} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rectangular" height={8}></Skeleton>
                    </TableCell>
                  </>
                )}
                <TableCell>Completed</TableCell>
                <TableCell>
                  <Checkbox value={1} checked={complianceFilter === 1 ? true : false} onChange={handleComplianceFilter} />
                </TableCell>
              </TableRow>
              <TableRow>
                {!promiseInProgress && (
                  <>
                    <TableCell>
                      <span className="compliance-box background-inactive">{complianceCounts.inactive}</span>
                    </TableCell>
                    <TableCell>
                      <LinearProgress sx={{ height: '8px' }} variant="determinate" color="inactive" value={complianceCounts.inactivePercentage} />
                    </TableCell>
                  </>
                )}
                {promiseInProgress && (
                  <>
                    <TableCell>
                      <Skeleton variant="rounded" width={24} height={23} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rectangular" height={8}></Skeleton>
                    </TableCell>
                  </>
                )}
                <TableCell>Service Inactive</TableCell>
                <TableCell>
                  <Checkbox value={4} checked={complianceFilter === 4 ? true : false} onChange={handleComplianceFilter} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
        <Card variant="outlined" className="flex-1">
          <div className="box-header">Stats</div>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '90%' }}>Total Required Courses</TableCell>
                <TableCell sx={{ width: '10%' }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Past Due</TableCell>
                {!promiseInProgress && (
                  <TableCell>
                    <span className={`${userTrainingStatus?.pastDue && 'color-danger'}`}>
                      {userTrainingStatus?.pastDue ? userTrainingStatus.pastDue : 0}
                    </span>
                  </TableCell>
                )}
                {promiseInProgress && (
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                )}
              </TableRow>
              <TableRow>
                <TableCell>Training Due</TableCell>
                {!promiseInProgress && (
                  <TableCell>
                    <span className={`${userTrainingStatus?.dueSoon && 'color-warning'}`}>
                      {userTrainingStatus?.dueSoon ? userTrainingStatus.dueSoon : 0}
                    </span>
                  </TableCell>
                )}
                {promiseInProgress && (
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                )}
              </TableRow>
              <TableRow>
                <TableCell>Completed</TableCell>
                {!promiseInProgress && (
                  <TableCell>
                    <span className={`${userTrainingStatus?.completed && 'color-success'}`}>
                      {userTrainingStatus?.completed ? userTrainingStatus.completed : 0}
                    </span>
                  </TableCell>
                )}
                {promiseInProgress && (
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </div>
      <Card variant="outlined">
        <div className="box-header">
          {!promiseInProgress && (
            <>
              {filteredCustomers.length} Practice{filteredCustomers.length > 1 ? 's' : ''}
            </>
          )}
          {promiseInProgress && <Skeleton variant="text" width={80} />}
        </div>
        <div>
          {customers.length > 0 && (
            <div className="filter-container flex flex-space-between">
              <div className="flex">
                <FormControl sx={{ mr: 2, minWidth: 200 }} size="small">
                  <InputLabel>Filter by Compliance</InputLabel>
                  <Select value={complianceFilter} label="Filter by Compliance" onChange={handleComplianceFilter}>
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={3}>Past Due</MenuItem>
                    <MenuItem value={2}>Training Due</MenuItem>
                    <MenuItem value={1}>Completed</MenuItem>
                    <MenuItem value={4}>Service Inactive</MenuItem>
                  </Select>
                </FormControl>
                {groupMembers.length > 0 && (
                  <Autocomplete
                    key={groupMembers}
                    multiple
                    onChange={(event, newValue) => {
                      handleGroups(newValue);
                    }}
                    options={groupMembers}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {option.name}
                      </li>
                    )}
                    size="small"
                    style={{ minWidth: 200, maxWidth: 500, marginRight: '16px' }}
                    renderInput={(params) => <TextField {...params} label="Filter by Group" />}
                  />
                )}
                {courses.length > 0 && (
                  <FormControl sx={{ mr: 2, minWidth: 200 }} size="small">
                    <InputLabel>Filter by Course</InputLabel>
                    <Select value={courseFilter} label="Filter by Compliance" onChange={handleCourse}>
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {courses.map((course, key) => {
                        return (
                          <MenuItem key={key} value={course.value}>
                            {course.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </div>
              <TextField
                size="small"
                variant="outlined"
                placeholder="Search"
                onChange={handleSearch}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <FontAwesomeIcon icon="magnifying-glass" size="sm" className="m-l-8" />
                    </IconButton>
                  )
                }}
              />
            </div>
          )}
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ width: '10%' }}
                  onClick={() => {
                    handleSort('training_compliance_status');
                  }}
                  className="pointer"
                >
                  Status {showSortIcon('training_compliance_status')}
                </TableCell>
                <TableCell
                  sx={{ width: '30%' }}
                  onClick={() => {
                    handleSort('name');
                  }}
                  className="pointer"
                >
                  Name {showSortIcon('name')}
                </TableCell>
                <TableCell
                  sx={{ width: '25%' }}
                  onClick={() => {
                    handleSort('city');
                  }}
                  className="pointer"
                >
                  Location {showSortIcon('city')}
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleSort('training_past_due_count');
                  }}
                  className="pointer"
                >
                  Past Due {showSortIcon('training_past_due_count')}
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleSort('training_due_soon_count');
                  }}
                  className="pointer"
                >
                  Training Due {showSortIcon('training_due_soon_count')}
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleSort('training_completed_count');
                  }}
                  className="pointer"
                >
                  Completed {showSortIcon('training_completed_count')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!promiseInProgress &&
                displayedCustomers.map((customer, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <span className={`compliance-circle m-l-16 ${complianceColors[customer.training_compliance_status - 1]}`}></span>
                      </TableCell>
                      <TableCell>
                        <Link href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/practice/${customer.customer_number}/training`}>
                          {customer.name.trim()} (#{customer.customer_number.trim()})
                        </Link>
                      </TableCell>
                      <TableCell>
                        {customer.city.trim()}, {customer.state.trim()}
                      </TableCell>
                      <TableCell>
                        <span className={`block center ${customer.training_past_due_count > 0 && 'color-danger'}`}>
                          {customer.training_past_due_count}
                        </span>
                      </TableCell>
                      <TableCell>
                        <span className={`block center ${customer.training_due_soon_count > 0 && 'color-warning'}`}>
                          {customer.training_due_soon_count}
                        </span>
                      </TableCell>
                      <TableCell>
                        <span className={`block center ${customer.training_completed_count > 0 && 'color-success'}`}>
                          {customer.training_completed_count}
                        </span>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {promiseInProgress &&
                [...Array(15)].map((a, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Skeleton variant="circular" className="m-l-16" width={10} height={10} />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {!promiseInProgress && filteredCustomers.length === 0 && <div className="p-16 center">There are no matching practices</div>}
          {!promiseInProgress && filteredCustomers.length !== 0 && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={filteredCustomers.length}
              rowsPerPage={pageLimit}
              page={tablePage}
              onPageChange={handleTablePage}
              onRowsPerPageChange={handlePageLimit}
              sx={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}
            />
          )}
        </div>
      </Card>
    </div>
  );
}

export default TrainingComplianceReport;
