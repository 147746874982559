import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Collapse, CssBaseline, ListSubheader } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './PracticeSidebar.css';
import { infectionControlIcon, medicationIcon, oshaHipaaIcon } from '../icons';
import constants from '../constants';
import { Link } from 'react-router-dom';
import { HFSubMenu } from '../themes/components/ListItemButton';
import { useAppSelector } from '../redux/store';
import { UserAccessLevels, selectCurrentUser } from '../redux/user';
import { selectCustomerNumber } from '../redux/customer';
import { getEPayLink } from '../services/epayService';
import { ErrorNotify } from './Notify.styled';

export default function PracticeSidebar() {
  const [drawerName, setOpen] = React.useState('');
  const [alertShow, setAlertShow] = React.useState(false);
  const drawerWidth = 220;
  const currentUser = useAppSelector(selectCurrentUser);
  const customerNumber = useAppSelector(selectCustomerNumber);

  const handleDropdown = (name: string | undefined) => {
    if (name === drawerName) {
      setOpen('');
    } else {
      setOpen(name || '');
    }
  };

  const hasPermission = (permissions: UserAccessLevels[]): boolean => permissions.some((perm) => perm === currentUser?.access_level);
  const openLinkToEPayPayments = async (): Promise<void> => {
    try {
      const link = await getEPayLink(customerNumber, 'payments');
      window.open(link, '_blank');
    } catch {
        setAlertShow(true);
    }
  };
  const openLinkToEPayPaymentHistory = async (): Promise<void> => {
    try {
      const link = await getEPayLink(customerNumber, 'paymentsHistory');
      window.open(link, '_blank');
    } catch {
        setAlertShow(true);
    }
  };
  const handleAlertDismiss = () => setAlertShow(false);

  return (
    <Box sx={{ display: 'flex' }}>
      <ErrorNotify open={alertShow} onClose={() => handleAlertDismiss()} />
      <CssBaseline />
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' }
          }}
          open
        >
          <List subheader={<ListSubheader>Account</ListSubheader>}>
            <ListItem key="Home" disablePadding>
              <ListItemButton component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/dashboard`}>
                <FontAwesomeIcon icon="house-chimney" size="sm" className="sidebar-icon" />
                <ListItemText primary="Home" disableTypography className="sidebar-text" />
              </ListItemButton>
            </ListItem>
            {hasPermission([UserAccessLevels.Admin, UserAccessLevels.Owner]) && (
              <>
                <ListItem key="Pay Your Balance" disablePadding>
                  <ListItemButton onClick={() => openLinkToEPayPayments()}>
                    <FontAwesomeIcon icon={['far', 'credit-card']} size="sm" className="sidebar-icon" />
                    <ListItemText primary="Pay Your Balance" disableTypography className="sidebar-text" />
                    <FontAwesomeIcon icon="circle-info" />
                  </ListItemButton>
                </ListItem>
                <ListItem key="Payment History" disablePadding>
                  <ListItemButton onClick={() => openLinkToEPayPaymentHistory()}>
                    <FontAwesomeIcon icon={['far', 'file-lines']} size="sm" className="sidebar-icon" />
                    <ListItemText primary="Payment History" disableTypography className="sidebar-text" />
                  </ListItemButton>
                </ListItem>
              </>
            )}
            <ListItem key="Shipment History" disablePadding>
              <ListItemButton component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/shipments/upcoming`}>
                <FontAwesomeIcon icon="truck" size="sm" className="sidebar-icon" />
                <ListItemText primary="Shipment Status" disableTypography className="sidebar-text" />
              </ListItemButton>
            </ListItem>
            <ListItem key="Auto Renewals" disablePadding>
              <ListItemButton component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/auto-renewals`}>
                <FontAwesomeIcon icon="arrows-rotate" size="sm" className="sidebar-icon" />
                <ListItemText primary="Auto Renewals" disableTypography className="sidebar-text" />
              </ListItemButton>
            </ListItem>
            {hasPermission([UserAccessLevels.Admin, UserAccessLevels.Owner]) && (
              <ListItem key="Team Members" disablePadding>
                <ListItemButton component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/team-members`}>
                  <FontAwesomeIcon icon="user" size="sm" className="sidebar-icon" />
                  <ListItemText primary="Team Members" disableTypography className="sidebar-text" />
                </ListItemButton>
              </ListItem>
            )}
          </List>
          <Divider />
          <List subheader={<ListSubheader>Compliance Reports</ListSubheader>}>
            <ListItem key="Meds & Devices" disablePadding>
              <ListItemButton onClick={() => handleDropdown('meds')}>
                <div className="alt-icon">
                  <img src={medicationIcon} />
                </div>
                <ListItemText primary="Meds & Devices" disableTypography className="sidebar-text" />
                <FontAwesomeIcon icon={drawerName === 'meds' ? 'chevron-down' : 'chevron-right'} size="xs" className="arrow-icon" />
              </ListItemButton>
            </ListItem>
            <Collapse in={drawerName === 'meds'}>
              <List component="div" disablePadding>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/medications`}>
                  Medications
                </HFSubMenu>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/devices`}>
                  Devices
                </HFSubMenu>
              </List>
            </Collapse>
            <ListItem key="Infection Control" disablePadding>
              <ListItemButton onClick={() => handleDropdown('infection')}>
                <div className="alt-icon">
                  <img src={infectionControlIcon} />
                </div>
                <ListItemText primary="Infection Control" disableTypography className="sidebar-text" />
                <FontAwesomeIcon icon={drawerName === 'infection' ? 'chevron-down' : 'chevron-right'} size="xs" className="arrow-icon" />
              </ListItemButton>
            </ListItem>
            <Collapse in={drawerName === 'infection'}>
              <List component="div" disablePadding>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/biological`}>
                  Biological Monitoring
                </HFSubMenu>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/waterline-management`}>
                  Waterline Management
                </HFSubMenu>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/air-surface-disinfection`}>
                  Air and Surface Disinfection
                </HFSubMenu>
              </List>
            </Collapse>
            <ListItem key="OSHA/HIPAA" disablePadding>
              <ListItemButton component={Link} to="/practice/osha-hipaa">
                <div className="alt-icon">
                  <img src={oshaHipaaIcon} alt="" />
                </div>
                <ListItemText primary="OSHA/HIPAA" disableTypography className="sidebar-text" />
              </ListItemButton>
            </ListItem>
            <ListItem key="Waste Management" disablePadding>
              <ListItemButton onClick={() => handleDropdown('waste')}>
                <FontAwesomeIcon icon="earth-americas" size="lg" className="sidebar-icon" />
                <ListItemText primary="Waste Management" disableTypography className="sidebar-text" />
                <FontAwesomeIcon icon={drawerName === 'waste' ? 'chevron-down' : 'chevron-right'} size="xs" className="arrow-icon" />
              </ListItemButton>
            </ListItem>
            <Collapse in={drawerName === 'waste'}>
              <List component="div" disablePadding>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/medicalwaste`}>
                  Sharps & Medical Waste
                </HFSubMenu>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/pharma-recovery`}>
                  Pharmaceutical Waste
                </HFSubMenu>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/amalgam-containers`}>
                  Amalgam Waste
                </HFSubMenu>
              </List>
            </Collapse>
            <ListItem key="Tasks" disablePadding>
              <ListItemButton onClick={() => handleDropdown('tasks')}>
                <FontAwesomeIcon icon="bars-progress" size="sm" className="sidebar-icon" />
                <ListItemText primary="Tasks" disableTypography className="sidebar-text" />
                <FontAwesomeIcon icon={drawerName === 'tasks' ? 'chevron-down' : 'chevron-right'} size="xs" className="arrow-icon" />
              </ListItemButton>
            </ListItem>
            <Collapse in={drawerName === 'tasks'}>
              <List component="div" disablePadding>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/covid19`}>
                  Infection Control Tasks
                </HFSubMenu>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/tasks`}>
                  Assigned Tasks
                </HFSubMenu>
              </List>
            </Collapse>
          </List>
          <Divider />
          <List>
            <ListItem disablePadding>
              <ListItemButton component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/training`}>
                <FontAwesomeIcon icon="graduation-cap" size="sm" className="sidebar-icon" />
                <ListItemText primary="Training" disableTypography className="sidebar-text" />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem key="Store" disablePadding>
              <ListItemButton component="a" target="_blank" href={constants.HEALTHFIRST_WEB_URL}>
                <FontAwesomeIcon icon="cart-shopping" size="sm" className="sidebar-icon" />
                <ListItemText primary="Store" disableTypography className="sidebar-text" />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List subheader={<ListSubheader>Documents</ListSubheader>}>
            <ListItem key="SDS" disablePadding>
              <ListItemButton component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/sds`}>
                <FontAwesomeIcon icon={['far', 'folder-open']} size="sm" className="sidebar-icon" />
                <ListItemText primary="SDS" disableTypography className="sidebar-text" />
              </ListItemButton>
            </ListItem>
            <ListItem key="Reports" disablePadding>
              <ListItemButton onClick={() => handleDropdown('reports')}>
                <FontAwesomeIcon icon="chart-column" className="sidebar-icon" />
                <ListItemText primary="Reports" disableTypography className="sidebar-text" />
                <FontAwesomeIcon icon={drawerName === 'reports' ? 'chevron-down' : 'chevron-right'} size="xs" className="arrow-icon" />
              </ListItemButton>
            </ListItem>
            <Collapse in={drawerName === 'reports'}>
              <List component="div" disablePadding>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/certificates`}>
                  Certificates
                </HFSubMenu>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/quarterly-reports`}>
                  Quarterly Reports
                </HFSubMenu>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/annual-reports`}>
                  Annual Reports
                </HFSubMenu>
              </List>
            </Collapse>
          </List>
          <Divider />
          <List>
            <ListItem key="Help Center" disablePadding>
              <ListItemButton onClick={() => handleDropdown('help')}>
                <FontAwesomeIcon icon="circle-question" className="sidebar-icon" />
                <ListItemText primary="Help Center" disableTypography className="sidebar-text" />
                <FontAwesomeIcon icon={drawerName === 'help' ? 'chevron-down' : 'chevron-right'} size="xs" className="arrow-icon" />
              </ListItemButton>
            </ListItem>
            <Collapse in={drawerName === 'help'}>
              <List component="div" disablePadding>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/contact`}>
                  Contact Us
                </HFSubMenu>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/faq`}>
                  FAQ
                </HFSubMenu>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/terms`}>
                  Terms & Condition
                </HFSubMenu>
                <HFSubMenu>Take the Tour</HFSubMenu>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/guides`}>
                  Video Guides
                </HFSubMenu>
              </List>
            </Collapse>
          </List>
        </Drawer>
      </Box>
    </Box>
  );
}
